<template>
  <div class="admin appointments">

    <header class="admin-header cf">
      <h1>
        <router-link to="/admin/receipts">Quittungen</router-link>
        <p>
          {{ receipt.receipt_number }}
        </p>
      </h1>
      <div class="meta">
        <a @click="update_receipt" class="button button-red" style="margin-right:10px">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
          </svg>
          <span>Speichern</span>
        </a>
        <a v-if="pdf_loading == false" @click="download_pdf" class="button button-red">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="qrcode" class="svg-inline--fa fa-qrcode fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M0 224h192V32H0v192zM64 96h64v64H64V96zm192-64v192h192V32H256zm128 128h-64V96h64v64zM0 480h192V288H0v192zm64-128h64v64H64v-64zm352-64h32v128h-96v-32h-32v96h-64V288h96v32h64v-32zm0 160h32v32h-32v-32zm-64 0h32v32h-32v-32z"></path>
          </svg>
          <span>Ausdrucken</span>
        </a>
        <div v-else class="loading-wrap" style="display:inline-block;vertical-align:middle;padding:0;width:176px">
          <span class="loading-spinner" style="margin:auto"></span>
        </div>
      </div>
    </header>

    <div v-if="loading" class="loading-wrap">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>

      <div class="row row-gutter-20">
        <div class="col-12">
          <div class="content-wrap">
            <h3>Empfänger</h3>
            <div class="form-wrap">
              <textarea v-model="receipt.recipient" rows="6" class="form-input input-grey" id="recipient" name="recipient"></textarea>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="content-wrap" style="height:268px">
            <h3>Bezahlung</h3>

            <div style="text-align:center;border:2px solid #f1f1f1;padding: 20px;font-size: 24px;border-radius: 3px">
              <span v-if="receipt.payment_status == 'paid'" class="price" style="color:#07B400;">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" style="width:30px;height:30px;display:inline-block;vertical-align:middle;" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span style="display:inline-block;vertical-align:middle;font-weight:600;margin-left:5px;">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'eur', }).format(receipt.payment_amount) }}</span>
              </span>
              <span v-else class="price" style="color:#CC0000;">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" style="width:30px;height:30px;display:inline-block;vertical-align:middle;" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span style="display:inline-block;vertical-align:middle;font-weight:600;margin-left:5px;">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'eur', }).format(receipt.payment_amount) }}</span>
              </span>
            </div>

          </div>
        </div>
      </div>

      <div class="content-wrap">
        <h3>Leistungen</h3>

        <div v-if="created_services.length == 0" class="alert alert-info">
          <p>Aktuell sind noch keine Leistungen vorhanden.</p>
        </div>
        <div v-else>

          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Anzahl</th>
                <th>Gesamtbetrag</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="serv in created_services" v-bind:key="serv">
                <td width="50%">
                  <div class="form-wrap" style="margin:0 0 5px 0">
                    <input v-model="serv.service_name" type="text" class="form-input">
                  </div>
                  <div class="form-wrap" style="margin:0">
                    <textarea v-model="serv.service_description" type="text" class="form-input" rows="2"></textarea>
                  </div>
                </td>
                <td width="10%">
                  <div class="form-wrap" style="margin:0">
                    <input v-model="serv.service_amount" type="text" class="form-input">
                  </div>
                </td>
                <td width="30%">
                  <div class="form-wrap" style="margin:0">
                    <currency-input v-model="serv.service_price" :options="{ currency: 'EUR', locale: 'de-DE', hideCurrencySymbolOnFocus: false }" class="form-input" />
                  </div>
                </td>
                <td width="10%" class="actions">
                  <a @click="delete_service(serv)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

        </div>

        <p style="text-align:right;margin-bottom:0">
          <a @click="modal_add_service = true" class="button button-red">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"></path>
            </svg>
            <span>Leistung hinzufügen ›</span>
          </a>
        </p>

      </div>

    </div>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add_service">
      <svg @click="modal_add_service = false" style="width: 18px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="modal-close w-6 h-6" data-v-2836fdb5-s=""><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" data-v-2836fdb5-s=""></path></svg>
      <div class="modal-head">
        <h3>Leistungen hinzufügen</h3>
      </div>
      <div class="modal-body">

        <div class="row row-gutter-10">
          <div class="col-8">
            <div class="form-wrap select-wrap" style="margin-bottom:7px">
              <label for="category_id">Standort</label>
              <select v-model="modal_service.location_id" @change="getCategories()" class="form-input" style="padding:8px 12px">
                <option v-for="location in computed_locations" v-bind:key="location.id" v-bind:value="location.id">{{ location.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-8">
            <div class="form-wrap select-wrap" style="margin-bottom:7px">
              <label for="category_id">Kategorie</label>
              <div v-if="!modal_service.location_id" class="form-input" style="cursor:not-allowed;height:35px;background:#f1f1f1;"></div>
              <div v-else-if="modal_service.loading_categories" class="loading-wrap" style="padding: 0">
                <span class="loading-spinner" style="height:18px;width:18px;"></span>
              </div>
              <select v-else v-model="modal_service.category_id" @change="getServices()" class="form-input" style="padding:8px 12px">
                <option v-for="category in modal_service.categories" v-bind:key="category.id" v-bind:value="category.id">{{ category.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-8">
            <div class="form-wrap select-wrap" style="margin-bottom:7px">
              <label for="category_id">Leistung</label>
              <div v-if="!modal_service.category_id" class="form-input" style="cursor:not-allowed;height:35px;background:#f1f1f1;"></div>
              <div v-else-if="modal_service.loading_services" class="loading-wrap" style="padding: 0">
                <span class="loading-spinner" style="height:18px;width:18px;"></span>
              </div>
              <select v-else v-model="modal_service.service_id" @change="getFees()" class="form-input" style="padding:8px 12px">
                <option v-for="service in modal_service.services" v-bind:key="service.id" v-bind:value="service.id">{{ service.name }}</option>
              </select>
            </div>
          </div>
        </div>

        <p style="margin: 5px 0 0 0;font-weight:600;font-size:14px;">Ausgewählte Leistungen:</p>

        <div v-if="create_services.length == 0" style="margin-top:10px">
          Bitte eine Leistung auswählen.
        </div>
        <div v-for="servi in create_services" v-bind:key="servi.id" style="margin-top:20px;position:relative;padding:14px;border:1px solid #f1f1f1;border-radius:5px">

          <a @click="remove_service(servi)" style="display:block;position:absolute;right:14px;top:-15px;background:#f1f1f1;padding:2px 12px;font-size:11px;border-radius:5px;">
            <svg style="position:relative;right:inherit;top:0;left:0;display:inline-block;vertical-align:middle;width: 14px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="modal-close w-6 h-6" data-v-2836fdb5-s=""><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" data-v-2836fdb5-s=""></path></svg>
            <span style="display:inline-block;vertical-align:middle;margin-left:3px">Leistung entfernen</span>
          </a>

          <div class="row row-gutter-10">
            <div class="col-8">
              <div class="form-wrap select-wrap" style="margin-bottom:7px">
                <label for="category_id">Standort</label>
                <small>{{ get_location(servi) }}</small>
              </div>
            </div>
            <div class="col-8">
              <div class="form-wrap select-wrap" style="margin-bottom:7px">
                <label for="category_id">Kategorie</label>
                <small>{{ get_category(servi) }}</small>
              </div>
            </div>
            <div class="col-8">
              <div class="form-wrap select-wrap" style="margin-bottom:7px">
                <label for="category_id">Leistung</label>
                <small>{{ get_service(servi) }}</small>
              </div>
            </div>
          </div>

          <div class="row row-gutter-10">
            <div class="col-12">
              <div class="form-wrap" style="margin-bottom:7px">
                <label for="service_name" class="form-label">Name</label>
                <input v-model="servi.service_name" class="form-input" type="text" name="service_name" id="service_name"  style="padding:8px 12px">
              </div>
            </div>
            <div class="col-12">
              <div class="form-wrap" style="margin-bottom:7px">
                <label for="service_description" class="form-label">Beschreibung</label>
                <input v-model="servi.service_description" class="form-input" type="text" name="service_description" id="service_description"  style="padding:8px 12px">
              </div>
            </div>
          </div>

          <div class="row row-gutter-20">
            <div class="col-8">
              <div v-if="servi.fees && servi.fees.length > 0">
                <div class="form-wrap select-wrap" style="margin-bottom:0">
                  <label for="category_id">Gebühr</label>
                  <select v-model="servi.fee_id" @change="change_amount(servi)" class="form-input"  style="padding:8px 12px">
                    <option v-for="fee in servi.fees" v-bind:key="fee.id" v-bind:value="fee.id">{{ fee.name }} ({{ fee.price_formatted }})</option>
                  </select>
                </div>
              </div>
              <div v-else>
                <span style="color:white">_</span>
              </div>
            </div>
            <div class="col-8">
              <div class="form-wrap" style="margin-bottom:0">
                <label for="service_amount" class="form-label">Anzahl</label>
                <input v-model="servi.service_amount" @change="change_amount(servi)" class="form-input" type="text" name="service_amount" id="service_amount"  style="padding:8px 12px">
              </div>
            </div>
            <div class="col-8">
              <div class="form-wrap" style="margin-bottom:0">
                <label for="service_price" class="form-label">Preis</label>
                <currency-input v-model="servi.service_price" :options="{ currency: 'EUR', locale: 'de-DE', hideCurrencySymbolOnFocus: false }" class="form-input" style="padding:8px 12px"/>
              </div>
            </div>
          </div>
        </div>

        <div v-if="create_services.length > 0" class="cf" style="margin-top:15px">
          <!--<a @click="add_create_service" class="button button-light-gray button-small" style="float:left;padding: 0.5rem 1rem">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            <span>Weitere Leistung</span>
          </a>-->
          <a @click="createService" class="button button-red button-small" style="float:right;padding: 0.5rem 1rem">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"></path>
            </svg>
            <span>Leistungen hinzufügen</span>
          </a>
        </div>

      </div>
      <!--<div class="modal-meta">
        <a @click="create_service" class="button button-red button-100">Leistung hinzufügen ›</a>
      </div>-->
    </vue-final-modal>

  </div>
</template>

<script>
import CurrencyInput from '@/components/CurrencyInput'

export default {
  name: 'admin_receipt',
  components: { CurrencyInput },
  data () {
    return {
      loading: true,
      pdf_loading: false,
      modal_add_service: false,
      locations: [],
      categories: [],
      services: [],
      fees: [],
      fee: null,
      receipt: {},
      service: {},
      user: {},
      user_location: null,
      create_services: [],
      created_services: [],
      create_nav_service: {},
      modal_service: {}
    }
  },
  computed: {
    computed_locations: function () {
      var locations = [];
      this.locations.forEach(location => {
        if (this.user_location) {
          if (this.user_location == location.id) {
            locations.push(location);
          }
        } else {
          locations.push(location);
        }
      })
      return locations;
    },
  },
  methods: {
    async get_data() {
      /*this.create_services.push({
        id: this.create_services.length+1,
        location_id: null,
        categories: [],
        category_id: null,
        services: [],
        service_id: null,
        service_amount: 1
      })*/
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/locations', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.locations = response.data.locations;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/user', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.user_id = response.data.user.id;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/users/'+this.user_id, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.user = response.data.user;
        if (this.user.locations && this.user.locations.length > 0) {
          this.user_location = this.user.locations[0].id;
          this.create_nav_service.location_id = this.user_location
        }
      })

      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/receipts/'+this.$route.params.uuid, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.receipt = response.data.receipt;
        this.created_services = response.data.receipt.services;
        if (this.receipt.location_id) {
          this.create_nav_service.location_id = this.receipt.location_id
        }
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/locations', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.locations = response.data.locations;
      })
      if (this.create_nav_service.location_id) {
        //this.getCategories(this.create_nav_service)
        this.modal_service.location_id = this.create_nav_service.location_id
        this.getCategories()
      }
      this.loading = false;
    },
    createService() {
      for (let i = 0; i < this.create_services.length; i += 1) {
        if (this.create_services[i].service_name != "" && this.create_services[i].service_amount != "" != "" && this.create_services[i].service_id != "") {
          this.created_services.push(this.create_services[i]);
          /*this.service = {};
          if (this.user_location) {
            this.service.location_id = null;
            this.service.location_id = this.user_location;
          }*/
          this.modal_add_service = false;
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success"
          });
        } else {
          this.$notify({
            title: "Bitte Name, Anzahl und Preis ausfüllen.",
            type: "error"
          });
        }
      }
      this.create_services = []
      /*this.create_services.push({
        id: this.create_services.length+1,
        location_id: null,
        categories: [],
        category_id: null,
        services: [],
        service_id: null,
        service_amount: 1
      })*/
      if (this.receipt.location_id) {
        //this.create_services[0].location_id = this.receipt.location_id
        this.modal_service.location_id = this.receipt.location_id
        this.getCategories()
      }
    },
    async update_receipt() {
      await this.$http.delete(process.env.VUE_APP_BASE_API+'/admin/receipts/'+this.$route.params.uuid+'/services/delete_all', { headers: { Authorization: this.$store.getters.get_token } })
      for (let i = 0; i < this.created_services.length; i += 1) {
        await this.$http.post(process.env.VUE_APP_BASE_API+'/admin/receipts/'+this.$route.params.uuid+'/services', {
          service_id: this.created_services[i].service_id,
          receipt_id: this.receipt.id,
          service_name: this.created_services[i].service_name,
          service_description: this.created_services[i].service_description,
          service_amount: this.created_services[i].service_amount,
          service_price: this.created_services[i].service_price
        }, { headers: { Authorization: this.$store.getters.get_token } })
      }
      await this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/receipts/'+this.$route.params.uuid, {
        recipient: this.receipt.recipient,
        status: this.receipt.status,
      }, { headers: { Authorization: this.$store.getters.get_token } })
      this.$notify({
        title: "Erfolgreich gespeichert.",
        type: "success"
      });
      this.get_data();
    },
    delete_service(service) {
      this.created_services.splice(this.created_services.indexOf(service), 1);
    },
    async download_pdf() {
      await this.update_receipt();
      this.pdf_loading = true;
      return(this.$http.get(process.env.VUE_APP_BASE_API+'/admin/receipts/'+this.$route.params.uuid+'/pdf', { responseType: 'blob', headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/octet-stream' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          var pdf_name = "Termin-"+this.receipt.id+".pdf"
          link.download = pdf_name
          link.click()
          URL.revokeObjectURL(link.href);
          this.pdf_loading = false;
      }))
    },
    add_create_service() {
      this.create_services.push({
        id: this.create_services.length+1,
        location_id: null,
        categories: [],
        category_id: null,
        services: [],
        service_id: null,
        service_amount: 1
      })
    },
    delete_create_service(create_service) {
      console.log(create_service)
      //this.create_services.splice(this.create_services.indexOf(create_service), 1);
    },
    async getCategories() {
      this.modal_service.loading_categories = true;
      var create_service_obj = this.create_nav_service;
      create_service_obj.category_id = null;
      create_service_obj.service_id = null;
      create_service_obj.date = null;
      create_service_obj.time = null;
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/categories', { params: { location_id: this.modal_service.location_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        create_service_obj.categories = response.data.categories;
      })
      this.modal_service = create_service_obj;
      this.modal_service.loading_categories = false;
    },
    async getServices() {
      this.modal_service.loading_services = true;
      var create_service_obj = this.create_nav_service;
      create_service_obj.service_id = null;
      create_service_obj.date = null;
      create_service_obj.time = null;
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services', { params: { category_id: this.modal_service.category_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        create_service_obj.services = response.data.services;
      })
      this.modal_service = create_service_obj;
      this.modal_service.loading_services = false;
    },
    deepClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    async getFees() {
      if (this.modal_service) {
        var selected_service = null;
        this.modal_service.loading_fees = true;
        var create_service_obj = this.deepClone(this.modal_service);
        create_service_obj.fee_id = null;
        create_service_obj.services.forEach(service => {
          if (service.id == create_service_obj.service_id) {
            selected_service = service;
          }
        })
        await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services/'+create_service_obj.service_id+'/fees', {
            headers: { Authorization: this.$store.getters.get_token }
          })
          .then(response => {
            create_service_obj.fees = response.data.fees;
          })
        if (selected_service && selected_service.name) {
          create_service_obj.service_name = selected_service.name;
        }
        if (selected_service && selected_service.description) {
          create_service_obj.service_description = selected_service.description;
        }
        create_service_obj.service_amount = 1;
        if (selected_service && selected_service.price) {
          create_service_obj.service_price_og = parseFloat(selected_service.price);
          create_service_obj.service_price = parseFloat(selected_service.price);
        } else {
          create_service_obj.service_price = parseFloat(0.0);
        }
        this.create_services.push(create_service_obj);
        this.modal_service.loading_fees = false;
        // RESET
        this.modal_service.service_id = null;

      }
    },
    change_amount(create_service) {
      var selected_fee = null;
      create_service.fees.forEach(fee => {
        if (fee.id == create_service.fee_id) {
          selected_fee = fee;
        }
      })
      if (selected_fee) {
        create_service.service_price = parseFloat(create_service.service_amount*selected_fee.price);
      } else {
        create_service.service_price = parseFloat(create_service.service_amount*create_service.service_price_og);
      }
    },
    remove_service(service) {
      this.create_nav_service.service_id = null;
      this.create_services.splice(this.create_services.indexOf(service), 1);
    },
    get_location(create_service) {
      const location = this.locations.find((el) => el.id == create_service.location_id)
      if (location) {
        return location.name
      } else {
        return location
      }
    },
    get_category(create_service) {
      const category = create_service.categories.find((el) => el.id == create_service.category_id)
      if (category) {
        return category.name
      } else {
        return category
      }
    },
    get_service(create_service) {
      const service = create_service.services.find((el) => el.id == create_service.service_id)
      if (service) {
        return service.name
      } else {
        return service
      }
    }
  },
  mounted () {
    this.get_data();
  },
  watch: {
    'service.location_id': function() {
      this.$http.get(process.env.VUE_APP_BASE_API+'/admin/categories', { params: { location_id: this.service.location_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.categories = response.data.categories;
      })
    },
    'service.category_id': function() {
      this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services', { params: { category_id: this.service.category_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.services = response.data.services;
      })
    },
    'service.service_id': function() {
      var selected_service = null;
      this.services.forEach(service => {
        if (service.id == this.service.service_id) {
          selected_service = service;
        }
      })
      if (selected_service && selected_service.id) {
        this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services/'+selected_service.id+'/fees', {
          headers: { Authorization: this.$store.getters.get_token }
        })
        .then(response => {
          this.fees = response.data.fees;
        })
      }
      if (selected_service && selected_service.name) {
        this.service.service_name = selected_service.name;
      }
      if (selected_service && selected_service.description) {
        this.service.service_description = selected_service.description;
      }
      this.service.service_amount = 1;
      if (selected_service && selected_service.price) {
        this.service.service_price_og = parseFloat(selected_service.price);
        this.service.service_price = parseFloat(selected_service.price);
      }
    },
    'service.fee_id': function() {
      var selected_fee = null;
      this.fees.forEach(fee => {
        if (fee.id == this.service.fee_id) {
          selected_fee = fee;
        }
      })
      if (selected_fee) {
        this.service.fee_id = selected_fee.id;
        this.service.service_price = parseFloat(this.service.service_amount*selected_fee.price);
      }
    },
    'service.service_amount': function() {
      var selected_fee = null;
      this.fees.forEach(fee => {
        if (fee.id == this.service.fee_id) {
          selected_fee = fee;
        }
      })
      if (selected_fee) {
        this.service.service_price = parseFloat(this.service.service_amount*selected_fee.price);
      } else {
        this.service.service_price = parseFloat(this.service.service_amount*this.service.service_price_og);
      }
    }
  }
}
</script>
